export default {
    init: function () {
        // JavaScript to be fired on all pages

        $('.block__diensten__title').matchHeight();

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('[data-toggle="popover"]').popover();

        $('.product-slider__content').slick({
            dots: false,
            arrows: true,
            swipeToSlide: true,
            prevArrow: $('.slider__top__prev'),
            nextArrow: $('.slider__top__next'),
            asNavFor: '.product-slider__nav',
        });

        $('.product-slider__nav').slick({
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 4,
            focusOnSelect: true,
            asNavFor: '.product-slider__content',
        });

        $('.slick-slider').css('opacity', '1');

        $('.product__sidebar').click(function () {
            $(this).toggleClass('product__sidebar__open');
        });

        setTimeout(function () {
            $('.myusedparts__fixed__small').css('right','0');
        }, 6000);
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
